import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import s1 from '../images/csr/s1.jpg'
import s2 from '../images/senehasa.png'
import s3 from '../images/biz.jpg'
import s4 from '../images/csr/s4.jpg'
import s5 from '../images/csr/s5.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'



const ServicesPage = () => {
    return (
        <Layout pageTitle="PragICTS | Corporate Social Responsibility" og_desc="Our constributions">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>
                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>Corporate Social <span><br></br> Responsibility</span></h2>
                                
                                
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>

                    <section data-scrollax-parent="true" id="sec1">
                        <div class="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>CSR</div>
                        <div class="container">
                       
                            <div class="fl-wrap post-container">
                                <div class="row">
                                    <div class="col-md-12">
                                       
                                        <div class="post fl-wrap fw-post">
                                            <h2><a href="blog-single.html"><span>Corporate Social Responsibility</span></a></h2>
                                            
                                          
                                            
                                            <div class="blog-text fl-wrap">
                                                <div class="clearfix"></div>
                                                
                                                <p>Business transcends mere financial gains; it encompasses humanity, values, and the creation of exceptional individuals poised for progress and impact. In alignment with these principles, our Corporate Social Responsibility (CSR) initiatives aim to contribute meaningfully within our capacity.</p>
                                                

                                                <p>One of our flagship initiatives is the Online Presence Initiative for non-profit organizations. Annually, we undertake (02) projects where we assist non-profits in establishing a robust online presence, primarily through website development. This initiative aims to enhance their visibility and outreach, thereby enabling them to serve their communities more effectively.
                                                </p>
                                                <p>As part of our commitment, we provide the software development work for these websites free of charge. We invite discerning non-profit organizations to apply for this initiative by reaching out to us at csr@pragicts.com. Through this endeavor, we strive to leverage our expertise to support noble causes and foster positive change in society.</p>

                                               
                                            </div>
                                        </div>
                                                                         
                                     
                                      
                                       
                                    
                                    </div>
                                    <div class="col-md-12">
                                        <div class="details-wrap fl-wrap">
                                            
                                           
                                           
                                            <div class="clearfix"></div>
                                            <h4>Projects executed under this initiative are as follows</h4>
                                            <p>
                                            <img src={s3} ></img><br></br>
                                            myBIZ is a chamber established in Sri Lanka in 2018, incorporated as a company limited by guarantee. Its mission is to offer a strong platform that enables member companies to develop and reach their full potential.
                                            </p>
                                            <p>
                                            As an active member, PragICTS is proud to contribute to this mission by enhancing myBIZ’s online presence through the creation of its website <a href='https://mybizsl.lk' target='_blank'> (https://mybizsl.lk)</a>.
                                            </p>
                                            <p>This website will be progressively enhanced and expanded to provide a comprehensive and robust online presence to support all stakeholders in achieving their business goals and objectives.</p>
                                            <p>The website was launched on the 18th of September 2024 at the 150th myConnect milestone meeting at Manderina <a href='https://www.mandarinacolombo.com/' target='_blank'>(https://www.mandarinacolombo.com/)</a>  by the Chief Guest Mr. Hanif Yusoof, Founder and President at EFL Global<a href='https://efl.global/' target='_blank'> (https://efl.global/)</a></p>


                                           
                                            <p>
                                            <img src={s2} ></img><br></br>
                                            Senehasa Foundation (Senehasa Education Resource Research and Information Centre (SERRIC))
                                            </p>
                                            <p>
                                            Senehasa Education Resource Research and Information Centre (SERRIC), functioning under the Ministry of Defence and Ranaviru Seva Authority, aims to provide essential services to children with special needs belonging to families of the Tri Forces, Police, and the Civil Defence Force, as well as families from the civil society with the purpose of increasing their quality of life.
                                            </p>
                                            <p>PragICTS helped the Senehasa Foundation with its new web presence (website and social platforms), branding (new logo design), and corporate video to help them serve better with greater online visibility and reach. This was executed at the request and facilitation of Mrs. Soniya Kottegoda, Chief Supervision Officer Senehasa Foundation, and Deputy Chairperson Ranaviru Seva Authority.</p>

                                            


                                          
                                        </div>
                                        <div class="fl-wrap vertical-gallery">
                                               
                                               <div class="box-item">
                                                   <img src={s1} alt=""/>
                                                   <a href={s1} class="fet_pr-carousel-box-media-zoom   image-popup"><i class="fal fa-search"></i></a>
                                               </div>
                                                                       
                                           </div>

                                        <div class="pr-list fl-wrap">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <ul>
                                                        <li><span>Date :</span> October 2020  </li>
                                                        <li><span>Client :</span>  Senehasa.care </li>
                                                        <li><span>Website :</span> <a href="http://senehasa.care/" target="_blank">www.senehasa.care</a> </li>
                                                        <li><span>Location : </span>  <a href="https://www.google.com/maps/search/SERRIC,+Matha+Road,+Manning+Town,+Colombo/@6.9059243,79.8787778,15z/data=!3m1!4b1" target="_blank"> SERRIC, Colombo  </a></li>
                                                    </ul>
                                                </div>
                                               
                                            </div>
                                        </div>
                                                                 
                                       
                                    </div>
                                 
                               
                                  
                                    <div class="limit-box fl-wrap"></div>
                                </div>
                               
                          
                              
                            </div>
                          
                        </div>

                        
                        <div class="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div class="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div class="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div class="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div class="sec-lines"></div>
                    </section>
                
                
                 

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default ServicesPage
